.message,
.message.info {
  border: solid 1px #999 !important;
  background-color: #EEE !important;
  color: #000 !important;
  display: block !important;
  text-align: center !important;
  font-weight: bold !important;
  padding: 5px !important; }

.message.success {
  background-color: #cdfccf !important;
  border-color: #077a0d !important;
  color: #077a0d !important; }

.message.warning {
  background-color: #fad3a3 !important;
  border-color: #c7720a !important;
  color: #ad6308 !important; }

.message.error {
  background-color: #fcc3c3 !important;
  border-color: #940707 !important;
  color: #940707 !important; }
